export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: 'revuGPT',
  description: 'Reviews + GPT',
  navItems: [
    {
      label: 'Home',
      href: '/',
    },
    {
      label: 'Places',
      href: '/places',
    },
    {
      label: 'Terms',
      href: '/terms',
    },
  ],
  links: {
    places: '/places',
    gpt: 'https://chat.openai.com/g/g-JlgkURitv',
  },
};
