'use client';

import { Link } from '@nextui-org/link';
import { button as buttonStyles } from '@nextui-org/theme';
import { siteConfig } from '@/config/site';
import { title } from '@/components/primitives';

export default function Home() {
  return (
    <div className="flex flex-col items-center min-h-full justify-center">
      <div className="flex flex-col max-w-5xl text-center justify-center gap-2">
        <div>
          <h1 className={title() + ' text-primary-500'}>retrieve </h1>
          <h1 className={title()}>reviews</h1>
        </div>
        <br />
        <div>
          <h1 className={title() + ' text-primary-500'}>analyze </h1>
          <h1 className={title()}>via LLM</h1>
        </div>
      </div>

      <div className="flex flex-row mt-8 gap-8">
        <Link
          href={siteConfig.links.places}
          className={buttonStyles({
            color: 'default',
            radius: 'full',
            variant: 'bordered',
          })}
        >
          Get started
        </Link>
        <Link
          isExternal
          href={siteConfig.links.gpt}
          className={buttonStyles({
            color: 'default',
            radius: 'full',
            variant: 'bordered',
          })}
        >
          GPT
        </Link>
      </div>
      <div className="mt-8 text-sm text-default-400">
        Restricted use, login required. GPT requires ChatGPT Plus subscription.
      </div>
    </div>
  );
}
